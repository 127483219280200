/*
 * @file: user.js
 * @description: Reducers and actions for store/manipulate user's  data
 * @date: 28.11.2019
 * @author: Mohit
 */

/******** Reducers ********/

const initialState = {
  loggedIn: false,
  notifications: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      return { ...state, loggedIn: true, ...action.data };

    case 'LOG_OUT':
      localStorage.removeItem('token');
      return initialState;

    default:
      const tokenExists = localStorage.getItem("token");
      if (!tokenExists) {
        return initialState;
      } else {
        return state;
      }
  }
}

