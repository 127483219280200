import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Use localStorage for persistence

// Reducers
import user from './modules/user';
import loader from './modules/loader';
import search from './modules/search';
import cart from './modules/cart';
import order from './modules/order';

// User-specific persist config
const userPersistConfig = {
    key: 'admin-app',
    storage: storage,
    blacklist: ['loader'], 
};

// Root reducer
const rootReducer = combineReducers({
    user: persistReducer(userPersistConfig, user),
    loader, // No persistence
    cart,   // Defaults to being persisted
    search, // Defaults to being persisted
    order,  // Defaults to being persisted
});

export default rootReducer;
