/*
 * @file: configureStore.prod.js
 * @description: Configure/creating redux store with thunk, reducer, and persistence
 * @author: Mohit Kumar
 */

import { applyMiddleware, createStore, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Default: localStorage for web
import {thunk} from 'redux-thunk';
import reducers from '../reducers';

export default () => {
    // Persist config
    const persistConfig = {
        key: 'root', // The key for the storage
        storage, // Use localStorage as the storage engine
        whitelist: ['authReducer', 'userReducer'], // Add reducers you want to persist
    };

    // Persisted reducer
    const persistedReducer = persistReducer(persistConfig, reducers);

    // Enhancers
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    // Create store with persisted reducer
    const store = createStore(
        persistedReducer,
        composeEnhancers(applyMiddleware(thunk))
    );

    // Persistor
    const persistor = persistStore(store);

    return { store, persistor };
};
