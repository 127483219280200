import React, { Suspense } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-input-2/lib/style.css";
import "react-quill/dist/quill.snow.css";
import "./scss/main.scss";
import configureStoreProd from "./Pages/config/configureStore.prod";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer } from "react-toastify";
import { lazy } from "react";

const { persistor, store } = configureStoreProd();

function App() {
  const naviagte = useNavigate();
  const routes = [
    { url: "/login", path: "Login" },
    { url: "/dashboard", path: "Dashboard" },
    { url: "*", path: "NotFoundPage" },
    { url: "/profile", path: "Profile" },
    { url: "/profile/:tab", path: "Settings" },
    { url: "/forgotpassword", path: "Forgotpassword" },
    { url: "/resetpassword", path: "Resetpassword" },
    { url: "/user", path: "Users" },
    { url: "/user/edit/:id", path: "Users/AddEdit" },
    { url: "/user/add", path: "Users/AddEdit" },
    { url: "/user/detail/:id", path: "Users/View" },
    { url: "/contractor", path: "Contractors" },
    { url: "/contractor/edit/:id", path: "Contractors/AddEdit" },
    { url: "/contractor/add", path: "Contractors/AddEdit" },
    { url: "/contractor/detail/:id", path: "Contractors/View" },
    // { url: "/order", path: "Skills" },
    // { url: "/order/edit/:id", path: "Skills/AddEdit" },
    // { url: "/order/add", path: "Skills/AddEdit" },
    // { url: "/order/detail/:id", path: "Skills/View" },
    { url: "/job", path: "Jobs" },
    { url: "/job/edit/:id", path: "Jobs/AddEdit" },
    { url: "/job/add", path: "Jobs/AddEdit" },
    { url: "/job/detail/:id", path: "Jobs/View" },
    { url: "/vendor", path: "Vendors" },
    { url: "/vendor/edit/:id", path: "Vendors/AddEdit" },
    { url: "/vendor/add", path: "Vendors/AddEdit" },
    { url: "/vendor/detail/:id", path: "Vendors/View" },
    { url: "/role", path: "Roles" },
    { url: "/role/edit/:id", path: "Roles/AddEdit" },
    { url: "/role/add", path: "Roles/AddEdit" },
    { url: "/role/detail/:id", path: "Roles/View" },
    { url: "/material", path: "Materials" },
    { url: "/material/edit/:id", path: "Materials/AddEdit" },
    { url: "/material/add", path: "Materials/AddEdit" },
    { url: "/material/detail/:id", path: "Materials/View" },
    { url: "/project", path: "Projects" },
    { url: "/project/edit/:id", path: "Projects/AddEdit" },
    { url: "/project/add", path: "Projects/AddEdit" },
    { url: "/project/detail/:id", path: "Projects/View" },

    { url: "/category", path: "Category" },
    { url: "/category/edit/:id", path: "Category/AddEdit" },
    { url: "/category/add", path: "Category/AddEdit" },
    { url: "/category/detail/:id", path: "Category/View" },

    { url: "/product", path: "Products" },
    { url: "/product/edit/:id", path: "Products/AddEdit" },
    { url: "/product/add", path: "Products/AddEdit" },
    // { url: "/product/detail/:id", path: "Products/View" },
    { url: "/brand", path: "Brand" },
    { url: "/brand/edit/:id", path: "Brand/AddEdit" },
    { url: "/brand/add", path: "Brand/AddEdit" },
    { url: "/brand/detail/:id", path: "Brand/View" },

    { url: "/home", element: <Navigate to="/" /> },
    { url: "/chat", path: "Chat" },
    { url: "/content", path: "Content" },
    { url: "/content/detail/:id", path: "Content/View" },
    { url: "/content/add", path: "Content/AddEdit" },
    { url: "/content/edit/:id", path: "Content/AddEdit" },
    { url: "/customers", path: "Customers" },
    { url: "/customers/edit/:id", path: "Customers/AddEdit" },
    { url: "/customers/add", path: "Customers/AddEdit" },
    { url: "/customers/detail/:id", path: "Customers/View" },
    { url: "/contract", path: "Contract" },
    { url: "/contract/detail/:id", path: "Contract/View" },
    // { url: "/blogs", path: "Blogs" },
    // { url: "/blogs/edit/:id", path: "Blogs/AddEdit" },
    // { url: "/blogs/add", path: "Blogs/AddEdit" },
    // { url: "/blogs/detail/:id", path: "Blogs/View" },
    { url: "/faqs", path: "Faq" },
    { url: "/faqs/edit/:id", path: "Faq/AddEdit" },
    { url: "/faqs/add", path: "Faq/AddEdit" },
    { url: "/faqs/detail/:id", path: "Faq/View" },
    { url: "/register", path: "Signup" },
    { url: "/products", path: "Productslisting" },
    { url: "/product/detail/:id/:catid?", path: "Productslisting/View" },
    { url: "/blogs", path: "Blogslisting" },
    { url: "/blog/detail/:id", path: "Blogslisting/View" },
    { url: "/saved", path: "SavesProductslisting" },
    { url: "/wish", path: "Wishlisting" },
    { url: "/cart", path: "Cart/Cartlisting" },
    { url: "/checkout", path: "Checkout/Checkoutlist" },
    { url: "/success/:id", path: "PaymentSuccess/Success" },
    { url: "/reviews/:id", path: "Reviewandrating/Review" },
    { url: "/cancel", path: "CancelPayment/Cancel" },
    { url: "/guest", path: "LoginGuest" },
    { url: "/", path: "Home" },
    { url: "/about-us", path: "Aboutus" },
    { url: "/contact-us", path: "Contactus" },
    { url: "/company", path: "Company" },
    { url: "/support", path: "Support" },
    { url: "/resources", path: "Resources" },
    { url: "/privacy-policy", path: "Privacypolicy" },
    { url: "/return-refund", path: "ReturnRefund" },
    { url: "/terms-sales", path: "Termsofsales" },
    { url: "/orders", path: "Orderhistory" },
    { url: "/orders/detail/:id?", path: "Orderhistory/View" },
    { url: "/ordertrack", path: "TrackOrder" },
  ];

  sessionStorage.clear();

  const router = () => {
    let route: any = localStorage.getItem("route");
    naviagte(route);
  };
  return (
    <>
      <div onClick={(e) => router()} id="routerDiv"></div>
      {/* <Provider store={store}> */}
      {/* <PersistGate loading={"loading ..."} persistor={persistor}> */}
      <Suspense
        fallback={
          <div id="loader" className="loaderDiv">
            <div>
              <img
                src="/assets/img/loader.gif"
                alt="logo"
                className="loaderlogo"
              />
            </div>
          </div>
        }
      >
        {/* <Router> */}
        <Routes>
          {routes.map((itm) => {
            const Element = lazy(() => import(`./Pages/${itm.path}`));
            return (
              <Route
                path={itm.url}
                element={itm.path ? <Element /> : itm.element}
              />
            );
          })}
        </Routes>
        {/* </Router> */}
      </Suspense>
      {/* </PersistGate> */}
      {/* </Provider> */}
      <div id="loader" className="loaderDiv d-none">
        <div>
          <img src="/assets/img/loader.gif" alt="logo" className="loaderlogo" />
        </div>
      </div>
      <ToastContainer position="top-right" />
    </>
  );
}

export default App;
