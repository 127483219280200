/*
 * @file: user.js
 * @description: Reducers and actions for store/manipulate user's  data
 * @date: 28.11.2019
 * @author: Mohit
 */

/******** Reducers ********/

const initialState = ""

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'CART_ADD':
      return action?.data||""
    default:
      return state
  }
}